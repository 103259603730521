<template>
  <vx-card title="DN Reversal">
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-button
          v-on:click="submit()"
          color="primary"
          icon-pack="feather"
          icon="icon-inbox"
          >Submit</vs-button
        >
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="
                  p-3
                  border border-solid
                  d-theme-border-grey-light
                  rounded-full
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-between
                  font-medium
                "
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th width="5%"></vs-th>
            <vs-th>
              <vs-checkbox
                v-on:click="approveAll()"
                v-model="statusApproveAll"
                color="primary"
                >Approve All</vs-checkbox
              >
            </vs-th>
            <vs-th>
              <vs-checkbox
                v-on:click="rejectAll()"
                v-model="statusRejectAll"
                color="warning"
                >Reject All</vs-checkbox
              >
            </vs-th>
            <vs-th>Supplier</vs-th>
            <vs-th>DN Number</vs-th>
            <vs-th>PO Number</vs-th>
            <vs-th>Received Date</vs-th>
            <vs-th>Warehouse</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              :class="data[indextr].class"
            >
              <vs-td class="whitespace-no-wrap">
                <template>
                  <vx-tooltip text="Show">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      @click.stop="handleEdit(tr, indextr)"
                      icon="icon-eye"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td>
                <vs-checkbox
                  v-on:click="
                    approve(
                      indextr,
                      data[indextr].dn_id,
                      data[indextr].purchase_return_id
                    )
                  "
                  :checked="dn_id_approve.includes(tr.dn_id)"
                  color="primary"
                ></vs-checkbox>
              </vs-td>
              <vs-td>
                <vs-checkbox
                  v-on:click="
                    reject(
                      indextr,
                      data[indextr].dn_id,
                      data[indextr].purchase_return_id
                    )
                  "
                  :checked="dn_id_reject.includes(tr.dn_id)"
                  color="warning"
                ></vs-checkbox>
              </vs-td>
              <vs-td :data="data[indextr].supplier_name">
                <div class="vx-row ml-0">
                  <span class="w-5/5"
                    >{{ data[indextr].supplier_code }} -
                    {{ data[indextr].supplier_name }}</span
                  >
                </div>
                <div class="vx-row ml-0">
                  <span class="w-5/5"
                    >{{ data[indextr].supplier_address }},
                    {{ data[indextr].supplier_city }} ({{
                      data[indextr].supplier_contact_name
                    }}
                    - {{ data[indextr].supplier_phone }})</span
                  >
                </div>
              </vs-td>
              <vs-td>
                {{ data[indextr].po_code ? data[indextr].dn_code : "-" }}
              </vs-td>
              <vs-td>
                {{ data[indextr].po_code ? data[indextr].po_code : "-" }}
              </vs-td>
              <template v-if="data[indextr].purchase_return_date != undefined">
                <vs-td :data="data[indextr].purchase_return_date">
                  {{
                    data[indextr].purchase_return_date
                  }}
                </vs-td>
              </template>
              <template v-else>
                <vs-td :data="data[indextr].received_date">
                  {{
                    data[indextr].received_date != '0001-01-01T00:00:00Z' && data[indextr].received_date != '' ? data[indextr].received_date : "-"
                  }}
                </vs-td>
              </template>
              <vs-td :data="data[indextr].warehouse_code">
                {{ data[indextr].warehouse_code }} <br />
                {{ data[indextr].warehouse_name }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <detail
              @close="handleClose"
              :dataDN="dataDN"
            >
            </detail>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import Detail from "./detail.vue";
export default {
  components: {
    Detail,
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      index1: 0,
      data: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      notes: "",
      statusApproveAll: false,
      statusRejectAll: false,
      dn_id_approve: [],
      dn_id_reject: [],
      purchase_return_id: [],
      persenTolerance: 0,
      dataDN:[],
    };
  },
  methods: {
    submit() {
      if (this.dn_id_approve.length == 0 && this.dn_id_reject.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Select data first.",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.loading();
        let param = {
          dn_id_approve: this.dn_id_approve,
          dn_id_reject: this.dn_id_reject,
          purchase_return_id: this.purchase_return_id,
        };
        this.$http
          .post("/api/v1/debit-note/approve-reject/reversal", param)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.dn_id_approve = [];
              this.dn_id_reject = [];
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    checkToleransiReturnValue() {
      this.data.map((r, i) => {
        const returnValueTotal = r.line.reduce(
          (acc, value) =>
            acc +
            parseInt(
              value.ReturnValue.toString()
                .replace(/[^,\d]/g, "")
                .toString()
            ),
          0
        );
        const dnValueTotal = r.line.reduce(
          (acc, value) =>
            acc +
            parseInt(
              value.DNValue.toString()
                .replace(/[^,\d]/g, "")
                .toString()
            ),
          0
        );
        let diff = 0;
        if (dnValueTotal >= returnValueTotal) {
          diff = dnValueTotal - returnValueTotal;
        } else {
          diff = returnValueTotal - dnValueTotal;
        }
        const checkPersen = parseFloat((diff / dnValueTotal) * 100);
        console.log(checkPersen, this.persenTolerance);
        if (checkPersen > this.persenTolerance) {
          r.class = "danger";
        }
      });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/dn", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.$userLogin.territory_id,
            territory_area_id: this.$userLogin.territory_area_id,
            status: 6,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_records;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_seardh;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;
            try {
              this.persenTolerance = JSON.parse(resp.data.setting.Value);
              this.persenTolerance = this.persenTolerance[0].percent;
            } catch (err) {
              this.persenTolerance = 100;
            }
            this.setStartEnd();
            this.$vs.loading.close();
            this.checkToleransiReturnValue();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleCreate() {
      this.detail = true;
    },
    handleEdit(data, index) {
      console.log("handleEdit", data)
      this.dn_id = data.dn_id;
      this.notes = data.notes;
      this.dn_code = data.dn_code;
      this.cn_number = data.cn_number;
      this.cn_date = data.cn_date == '0001-01-01' ? '' : data.cn_date;
      this.tr_number = data.tr_number;
      this.tr_date = data.tr_date == '0001-01-01' ? '' : data.tr_date;
      this.table.data = [];
      this.table.data = this.data;
      this.dataDN = data;

      this.table.data[this.index1].class = "";
      this.table.data[index].class = "highlight";
      this.detail = true;
      this.index1 = index;
    },
    approveAll() {
      this.statusRejectAll = false;
      let _this = this;
      this.table.data.forEach(function (element) {
        element.status_checked_reject = false;
        element.status_checked_approve = true;

        if (!_this.dn_id_approve.includes(element.dn_id)) {
          _this.dn_id_approve.push(element.dn_id);
        } else {
          var index2 = _this.dn_id_approve.indexOf(element.dn_id);
          if (index2 !== -1) {
            _this.dn_id_approve.splice(index2, 1);
          }
        }
      });
      this.dn_id_reject = [];
      this.purchase_return_id = [];
      console.log(this.purchase_return_id);

      // console.log(this.dn_id_approve);
      // console.log(this.dn_id_reject);
    },
    rejectAll() {
      this.statusApproveAll = false;
      let _this = this;
      this.table.data.forEach(function (element) {
        element.status_checked_reject = true;
        element.status_checked_approve = false;

        if (!_this.dn_id_reject.includes(element.dn_id)) {
          _this.dn_id_reject.push(element.dn_id);
          _this.purchase_return_id.push(element.purchase_return_id);
        } else {
          var index2 = _this.dn_id_reject.indexOf(element.dn_id);
          if (index2 !== -1) {
            _this.dn_id_reject.splice(index2, 1);
          }

          var index3 = _this.purchase_return_id.indexOf(
            element.purchase_return_id
          );
          if (index3 !== -1) {
            _this.purchase_return_id.splice(index3, 1);
          }
        }
      });
      this.dn_id_approve = [];
      console.log(this.purchase_return_id);

      // console.log(this.dn_id_approve);
      // console.log(this.dn_id_reject);
    },
    approve(index, dn_id, pr) {
      console.log("approve");
      if (!this.dn_id_approve.includes(dn_id)) {
        this.dn_id_approve.push(dn_id);
      } else {
        var index2 = this.dn_id_approve.indexOf(dn_id);
        if (index2 !== -1) {
          this.dn_id_approve.splice(index2, 1);
        }
      }
      var index1 = this.dn_id_reject.indexOf(dn_id);
      if (index1 !== -1) {
        this.dn_id_reject.splice(index1, 1);
      }

      if (!this.purchase_return_id.includes(pr)) {
        this.purchase_return_id.push(pr);
      } else {
        var index3 = this.purchase_return_id.indexOf(pr);
        if (index3 !== -1) {
          this.purchase_return_id.splice(index3, 1);
        }
      }

      console.log(this.purchase_return_id);
      // console.log(this.dn_id_approve);
      // console.log(this.dn_id_reject);
    },
    reject(index, dn_id, pr) {
      console.log("reject");
      if (!this.dn_id_reject.includes(dn_id)) {
        this.dn_id_reject.push(dn_id);
      } else {
        var index2 = this.dn_id_reject.indexOf(dn_id);
        if (index2 !== -1) {
          this.dn_id_reject.splice(index2, 1);
        }
      }

      var index1 = this.dn_id_approve.indexOf(dn_id);
      if (index1 !== -1) {
        this.dn_id_approve.splice(index1, 1);
      }

      if (!this.purchase_return_id.includes(pr)) {
        this.purchase_return_id.push(pr);
      } else {
        var index3 = this.purchase_return_id.indexOf(pr);
        if (index3 !== -1) {
          this.purchase_return_id.splice(index3, 1);
        }
      }
      console.log(this.purchase_return_id);

      // console.log(this.dn_id_approve);
      // console.log(this.dn_id_reject);
    },
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.danger > td {
  background-color: #f77f7f;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>